<script setup>
const props = defineProps({ blok: Object });

const activeTab = ref(0);
const tabItems = ref(props.blok.blocks);

const selectedItem = computed(() => tabItems.value[activeTab.value]);
</script>
<template>
  <div v-editable="blok" :class="{ 'tabs-wrap': blok.as_card }">
    <div class="d-flex">
      <div class="numbers-wrap mt-4">
        <template v-for="(item, i) in tabItems" :key="i">
          <div v-editable="item" class="tab-link-top" @click="activeTab = i">
            <div class="tab-number" :class="{ active: activeTab === i }">
              {{ i + 1 }}
            </div>
          </div>
          <v-divider v-if="i < tabItems.length - 1" vertical class="divider" />
        </template>
      </div>
      <div class="content-wrap relative w-50 mx-8">
        <template v-for="(item, i) in tabItems" :key="i">
          <v-fade-transition>
            <div v-show="activeTab === i" class="absolute">
              <h3 class="text-white mb-4">{{ selectedItem.toggler_text }}</h3>
              <div v-html="renderRichText(selectedItem.content)"></div>
            </div>
          </v-fade-transition>
        </template>
      </div>
      <div class="tabs-content w-tab-content w-50">
        <div
          v-for="(item, i) in tabItems"
          v-show="activeTab === i"
          :key="i"
          class="tab-pane w-tab-pane"
        >
          <NuxtImg
            v-if="item.image.filename"
            :src="item.image.filename"
            :alt="item.image.alt"
            loading="lazy"
            class="features-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v-expansion-panel {
  background-color: transparent;
}
.tab-number {
  cursor: pointer;
  line-height: 1;
  background-color: #494949;
  transition: all 0.3s;

  &:hover {
    background-color: #646464;
  }
}
.tab-link-top {
  padding: 10px 20px 10px 0;
}
.divider {
  height: 30px;
  margin-left: 25px;
}
// .numbers-wrap::before {
//   content: " ";
//   border-right: 1px solid #ffffff; // Schwarze vertikale Linie
//   height: 100%;
//   position: relative;
//   left: 40%;
//   top: 80px;
//   z-index: 0;
// }
</style>
